<template>
  <div class="button-row">
    <!-- 
    <b-button @click="follow">Follow</b-button>
    <followButton :id="bot.handle" expanded v-if="bot && !isOwner" :isBot="true"> </followButton>
    -->

    <template v-if="!isOwner">
      <div
        v-if="isFollowing"
        class="button btFollow is-lightN is-smallNO is-outlinedNO"
        icon-pack="fal"
        v-on:click="unfollow"
      >
        Following
      </div>

      <div v-else class="button btFollow is-lightNO is-smallNO" icon-pack="fal" v-on:click="follow">Follow</div>
    </template>
    <router-link
      v-if="isOwner"
      :to="'/bots/' + bot.handle + ''"
      style="margin-right: 20px"
      class="is-hidden-touchNOOO button"
    >
      <span class="icon"> <i class="far fa-cog" style="color: #aaa"></i></span>
      <span> Settings</span>
    </router-link>

    <!--   -->
    <template v-if="bot.enableMessaging !== false && chatTasks.length">
      <div
        v-if="bot.subEnabled && !hasValidSubscription && !isOwner"
        class="button is-dark"
        @click="subscribe()"
        style="margin-right: 20px"
      >
        <div>Message</div>
        <span class="icon"> <i class="far fa-lock" style="color: #aaa"></i></span>
      </div>

      <router-link v-else :to="'/messages/' + bot.handle + ''" class="button is-dark" style="margin-right: 20px">
        <span class="icon" v-if="bot.subEnabled"> <i class="far fa-lock-open" style="color: #aaa"></i></span>
        <div>Message</div>
      </router-link>
    </template>

    <router-link
      v-if="bot.enableCreate !== false && imageTasks.length"
      :to="'/images/' + bot.handle + ''"
      class="button is-dark"
    >
      <div>Create</div>
      <span class="icon"> <i class="far fa-sparkles" style="color: #aaa"></i></span>
    </router-link>

    <!-- 
    <router-link
      v-if="isOwner"
      :to="'/' + bot.handle + '/new'"
      style="margin-left: 20px"
      class="is-hidden-touchNOOO button"
    >
      <span class="icon"> <i class="far fa-sparkles" style="color: #aaa"></i></span>
      <span> Create post</span>
    </router-link>


    <router-link
      v-if="isOwner && !bot.enableMessaging"
      :to="'/' + bot.handle + '/manage'"
      style="margin-left: 20px"
      class="is-hidden-desktop button"
    >
      <span class="icon"> <i class="far fa-cog" style="color: #aaa"></i></span>
      <span> Configure</span>
    </router-link>

       

    <router-link v-if="isOwner" :to="'/' + bot.handle + '/manage'" style="margin-left: 20px" class="is-hidden-touch">
      <i class="fal fa-cog" style="color: #aaa"></i>
    </router-link>  -->

    <DotDrop :horizontal="true" :items="dropDownItems" class="is-hidden-touch" />

    <!--   
              <button class="primary">Follow</button> 
              <followButton :id="bot.handle" expanded v-if="bot" :isBot="true"> </followButton>

              <router-link :to="'/messages/' + bot.handle + ''">
                <button v-show="bot.enableMessaging" class="secondary">Message</button>
              </router-link>

              <a
                v-if="bot.discordAddToServerURL"
                :href="bot.discordAddToServerURL"
                target="_blank"
                style="margin-left: 20px"
              >
                <i class="fab fa-discord" style="color: #aaa"></i>
              </a>

              <router-link
                v-if="isOwner"
                :to="'/' + bot.handle + '/manage'"
                style="margin-left: 20px"
                class="is-hidden-touch"
              >
                <i class="fal fa-cog" style="color: #aaa"></i>
              </router-link>

              <router-link
                v-if="isOwner"
                :to="'/' + bot.handle + '/new'"
                style="margin-left: 20px"
                class="is-hidden-touch button"
              >
                <i class="fal fa-plus" style="color: #aaa"></i>
                new post
              </router-link>
 -->
  </div>
</template>

<script>
import followButton from "@/components/e/followButton.vue";
import DotDrop from "@/components/e/DotDrop.vue";
export default {
  //receive props: bot, isOwner
  components: {
    //  followButton,
    DotDrop,
  },
  props: {
    bot: {
      type: Object,
      required: true,
    },
    isOwner: {
      type: Boolean,
      default: false,
    },
    hasValidSubscription: {
      type: Boolean,
      default: false,
    },
    dropDownItems: {
      type: Array,
      default: () => [],
    },
    tasks: {
      type: Array,
      default: () => [],
    },
  },
  //computed drop
  computed: {
    isFollowing() {
      // I follow this guy already?
      return this.$store.main.getters.following.filter((id) => id == this.id).length;
      //return this.$store.main.state.followingIds.filter(id => id == this.id).length
    },
    id() {
      return this.bot?.handle;
    },
    chatTasks() {
      return this.tasks.filter((t) => t.type == "chat");
    },
    imageTasks() {
      return this.tasks.filter((t) => t.type == "image");
    },
  },
  methods: {
    subscribe() {
      alert("subscribe todo");
    },
    follow(event) {
      this.$store.main.commit("follow", this.id);
    },
    unfollow(event) {
      // alert('unfollow' + this.id );
      this.$store.main.commit("unfollow", this.id);
      //duh
    },
    settings() {
      // your settings logic here
    },
  },
};
</script>

<style>
.button-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;

  padding: 4px;
}
.button-row .button,
.button-row >>> .button {
  padding: 3px;
  display: block;
}

.btFollow {
  margin-right: 10px;
}

/* mobile only media query */
@media only screen and (max-width: 1024px) {
  .button-row {
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 1rem;

    padding: 4px;
    align-content: stretch;
  }
  .button-row .button {
    width: 100%;
  }
}
</style>
