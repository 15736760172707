<template>
  <b-modal :active.sync="show" has-modal-card scroll="keep" class="image-modal">
    <div class="modal-content">
      <img
        :src="source"
        alt="Full Image"
        class="preview-image"
        :class="{
          avatar: avatar,
        }"
      />
      <div class="image-actions">
        <!-- Download Button -->
        <a :href="source" target="_blank" download class="download-button">
          <b-icon icon="download"></b-icon> Download
        </a>
        <!-- Close Button -->
        <button @click="close" class="delete is-large"></button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    source: String,
  },
  data() {
    return {
      show: false,
      avatar: false,
    };
  },
  methods: {
    open(src, opt) {
      this.source = src;
      this.show = true;
      if (opt) {
        this.avatar = opt.avatar || false;
      }
    },
    close() {
      this.show = false;
    },
  },
};
</script>

<style scoped>
.image-modal {
  width: 100vw;
  height: 100vh;
}
.thumbnail-image {
  cursor: pointer;
}

.image-modal {
  width: 100vw;
  height: 100vh;
}

/* Modal Content Style */
.modal-content {
  display: flex;
  justify-content: center;
  align-items: center;
  /*
  height: 80vh;
  width: 90vw;
  */
}

/* Image Style */
.preview-image {
  max-width: 100%;
  max-height: 100%;
  display: block;
  /*
  width: auto;
  height: auto;
  min-height: 50vh; 
  min-width: 60vw;
  */
}
.image-actions {
  position: absolute;
  top: 10px;
  right: 10px;
}

.download-button {
  margin-right: 10px;
}

/* ... other styles ... */
</style>
