<template>
  <form action="">
    <div class="modal-card" style="width: auto; min-width: 400px">
      <header class="modal-card-head">
        <b-dropdown
          @change="changeBot"
          :scrollable="true"
          has-link
          :max-height="600"
          v-model="selectedBot"
          aria-role="list"
        >
          <template #trigger>
            <p class="modal-card-title">
              <img class="user-avatar avatar" :src="bot?.avatar" />
              {{ bot?.handle }}

              <!-- -
          - New post-->
            </p>
            <!-- 
            <h1 class="title is-3">

              
              {{ bot.name || bot.handle }}

              <router-link :to="bot.url + '/manage/plan'" class="icon-link">
                <span class="tag is-success is-light is-small is-mini" v-if="bot.visibilityMode == 'public'"
                  >Public bot
                </span>
                <span class="tag is-danger is-light is-small is-mini" v-else> Private bot </span>
              </router-link>
            </h1> -->
            <!--  
              <b-button
                :label="selectedBot.text"
                type="is-primary"
                :icon-left="selectedBot.icon"
                icon-right="menu-down"
              />-->
          </template>

          <b-dropdown-item v-for="(b, index) in bots" :key="index" :value="b.handle" aria-role="listitem">
            <div class="media">
              <figure class="image is-32x32 media-left">
                <img :src="b.avatar" class="avatar" />
              </figure>

              <div class="media-content">
                <h3>{{ b.handle }}</h3>
                <small>{{ b.access }}</small>
              </div>
            </div>
          </b-dropdown-item>
        </b-dropdown>

        <!-- EO drop -->

        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <div>
          <!-- Manual text   text-->

          <b-field label="" :type="validationErrors.text ? 'is-danger' : ''">
            <b-input
              v-model="text"
              type="textarea"
              :placeholder="dropFiles.length ? 'Write a caption' : 'Write something...'"
              expanded
            ></b-input>
            <p class="help is-danger">{{ validationErrors.text }}</p>
          </b-field>

          <div v-if="wantImage">
            <!--Manual photo  -->

            <!-- -->

            <!-- 
        <b-field class="file">
          <b-upload v-model="file" expanded>
            <a class="button is-primary is-fullwidth">
              <b-icon icon="upload"></b-icon>
              <span>{{ file.name || "Click to upload" }}</span>
            </a>
          </b-upload>
        </b-field>
        <b-field>-->
            <b-field class="file" v-show="!dropFiles.length">
              <b-upload v-model="dropFiles" :multiple="true" drag-drop expanded>
                <section class="section">
                  <div class="content has-text-centered">
                    <p>
                      <b-icon icon="upload" size="is-large"></b-icon>
                    </p>
                    <p>Drop your files here or click to upload</p>
                  </div>
                </section>
              </b-upload>
            </b-field>

            <div
              class="tags file-previews"
              :class="{ single: dropFiles.length == 1, multi: dropFiles.length > 1 }"
              v-show="dropFiles.length"
            >
              <span v-for="(file, index) in dropFiles" :key="index" class="tagRR is-primary">
                <img :src="dataUrl(file)" :alt="file.name" />
                <!--    {{ dataUrl(file) }}  
                <br />
                {{ file.name }} -->
                <button class="delete is-small" type="button" @click="deleteDropFile(index)"></button>
              </span>
            </div>

            <b-field label="" v-show="dropFiles && dropFiles.length > 1">
              <b-switch v-model="uplaodAsSeparatePosts" type="is-success" rounded>Upload as separate posts</b-switch>
            </b-field>
            <!-- 
            <b-field label="" :type="validationErrors.text ? 'is-danger' : ''">
              <b-input v-model="text" type="textarea" placeholder="Write something..." expanded></b-input>
              <p class="help is-danger">{{ validationErrors.text }}</p>
            </b-field>

            <b-button class="is-link" @click="clickUploadPhotos" :loading="uploading">Upload photos </b-button>
           -->
          </div>
          <!-- 
          <b-field label="Text" :type="validationErrors.text ? 'is-danger' : ''">
            <b-input v-model="text" type="textarea" placeholder="Write something..." expanded></b-input>
            <p class="help is-danger">{{ validationErrors.text }}</p>
          </b-field> 

          <b-button class="is-link" @click="clickUploadText">{{ isEdit ? "Save" : "Post" }}</b-button>

          -->
        </div>

        <div class="row" v-show="!wantImage">
          <b-tooltip :label="button.desc" v-for="button in buttons" :key="button.id">
            <div
              class="rounded-button"
              :class="{
                active: button.id == 'image' && wantImage,
              }"
              @click="selectMediaType(button.id)"
            >
              <i :class="button.icon"></i>
            </div>
          </b-tooltip>
        </div>
      </section>
      <footer class="modal-card-foot" style="justify-content: space-between">
        <b-button label="Back" @click="$emit('close')" />
        <b-button
          rounded
          type="is-info"
          @click="clickPost"
          style=""
          :loading="loading"
          :disabled="!text && !dropFiles.length"
          >{{ isEdit ? "Save" : uplaodAsSeparatePosts ? "Create " + dropFiles.length + " posts" : "Post" }}</b-button
        >
      </footer>
    </div>
  </form>
</template>

<script>
export default {
  props: {
    bot: {
      type: Object,
      required: false,
      default: null,
    },
    bots: {
      type: Array,
      required: false,
      default: () => [],
    },
    canCanel: {
      type: Boolean,
      default: true,
    },
    wantImage: {
      type: Boolean,
      default: false,
    },
    email: {
      type: String,
      default: "",
    },
    password: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "Text",
    },
    post: {
      type: Object,
      default: () => ({ text: "", image: "" }),
    },
  },
  data() {
    return {
      text: this.post.text,
      uplaodAsSeparatePosts: false,
      //image: this.post.image,
      validationErrors: {},
      generateWithAi: this.$route.query.ai == "true" ? true : false, //load bot default

      //  file: {},
      //image: "",
      dropFiles: [], //important upuadl
      uploading: false,
      loading: false,
      isEdit: false,
      buttons: [
        { id: "image", icon: "fa fa-image", desc: "Upload an image" },
        { id: "text-suggestions", icon: "fa fa-lightbulb", desc: "Generate a post using a prompt" },
        /*
        { id: "ai_image", icon: "fa fa-sparkles" },
        { id: "video", icon: "fa fa-video" },
        { id: "article", icon: "fa fa-file-alt" },
        */
      ],

      //

      aiPhotoPrompt: "",
    };
  },
  computed: {
    imageUrl() {
      //createds dataurl to show in browser...
      return this.image ? URL.createObjectURL(this.image) : "";
    },
    botId() {
      return this.bot?.handle;
    },
    /* */
    isText() {
      return this.$route.name === "newTextBotPost" || this.$route.name === "inboxNewConvoTask";
    },
    /*
    isTxt() {
      var n = this.$route.name;
      return n;
      if (this.$route.name && this.$route.name == "newTextBotPost") return true;
      else return false;
      return (this.$route.name = "newTextBotPost");
    },*/
    heading() {
      var a = this.isText;
      return a ? "Text Update 📝" : "Photo 📸";
    },
  },

  methods: {
    clickPost() {
      //no image uplaode
      if (this.dropFiles.length) {
        this.clickUploadPhotos();
      } else {
        this.clickUploadText();
      }
    },
    changeBot(botId) {
      /*
      var r = this.$route;
      var currentPath = r.path;
      var old = r.params.bot;
      var newPath = currentPath.replace(old, handle);

      this.$router.push({ name: "newBotPost", params: { bot: botId } });*/
      if (this.bot?.id == botId) return;
      var newBot = this.bots.find((b) => b.handle == botId);
      this.bot = newBot;
    },
    selectMediaType(id) {
      if (id == "image") {
        this.wantImage = true;
      } else if (id == "ai_image") {
        this.wantImage = true;
        this.generateWithAi = true;
      } else if (id == "video") {
        alert("tbd");
      } else if (id == "article") {
        alert("tbd");
      }
    },
    handleAiToggleActive() {
      var active = this.generateWithAi;
      //replace url query param "ai" with active
      var url = new URL(window.location.href);
      var searchParams = new URLSearchParams(url.search);
      searchParams.set("ai", active);
      url.search = searchParams.toString();
      var newUrl = url.toString();
      console.log(">>newUrl >> ", newUrl);
      //replace current state, dont push
      window.history.replaceState({}, "", newUrl);

      // this.generateWithAi = !this.generateWithAi;
    },
    dataUrl(i) {
      //createds dataurl to show in browser...
      return i ? URL.createObjectURL(i) : "";
    },
    deleteDropFile(index) {
      this.dropFiles.splice(index, 1);
    },
    clickGeneratePhoto() {
      var prompt = this.aiPhotoPrompt;
      var text = this.text;
      // generateBotImagePost;
      window.API.generateBotImagePost(this.botId, { prompt, text }).then((res) => {
        console.log(">>generateBotImagePost res >> ", res);
        this.redirectSuccess(res.postId);
      });
    },
    clickGenerateText() {
      alert("tbd");
    },
    clickUploadText() {
      //manual txt post
      var prompt = this.aiPhotoPrompt;
      var post = { text: this.text };
      this.loading = true;
      window.API.createBotPost(this.botId, post).then((res) => {
        console.log(">>createBotPost res >> ", res);
        this.loading = false;
        this.redirectSuccess(res.postId, "text");
      });
    },
    clickUploadPhotos() {
      this.validationErrors = {};

      if (!this.text.trim() && !this.dropFiles.length) {
        this.validationErrors.text = "Please enter some text.";
        return;
      }

      let formData = new FormData();
      //formData.append("singleImage", this.file);
      //TODO: extend support to more files
      console.log(this.dropFiles);
      //formData.append("dropFiles", this.dropFiles);
      for (let i = 0; i < this.dropFiles.length; i++) {
        //very imporant for Méultiple files
        formData.append("dropFiles", this.dropFiles[i]);
      }
      formData.append("text", this.text);
      formData.append("testttt", "📣📣s dsaf a📣📣s📣📣s");
      //uplaodAsSeparatePosts
      formData.append("uplaodAsSeparatePosts", this.uplaodAsSeparatePosts);
      console.log(">> formData >> ", formData);
      this.uploading = true;
      this.loading = true;
      window.API.createBotPost(this.botId, formData).then((res) => {
        console.log(">>createBotPost res >> ", res);
        this.uploading = false;
        this.loading = false;
        this.redirectSuccess(res.postId, "image");
      });

      /*
      const postData = { text: this.text, images: this.image };
      console.log(postData, "posting....");
      if (this.isEdit) {
        var postId = this.$route.params.postId;
        window.API.editPost(this.botId, postId, postData); // replace with your actual API call for editing posts
      } else {
        window.API.createBotPost(this.botId, postData); // replace with your actual API call for posting new posts
      }
*/
      this.resetForm();
    },
    redirectSuccess(postId) {
      var url = window.location.href;
      //encoded url

      //remove host, keep path
      // url = url.replace(window.location.origin, "");
      //url = encodeURIComponent(url);

      //this.$router.push({ name: "newBotPostFinish", params: { bot: this.botId, postId }, query: { re: url } });
      this.$router.push(this.bot.url + "?newpost=" + postId);
      //buefy toast success
      this.$buefy.toast.open({
        message: "Posted!",
        type: "is-success",
        position: "is-bottom",
        duration: 5000,
        queue: false,
      });
    },
    resetForm() {
      this.text = "";
      this.image = "";
    },
  },
};
</script>
<style scoped>
>>> textarea,
>>> textarea:focus,
>>> textarea:active,
>>> textarea:hover {
  border: 0px;

  box-shadow: none;
  outline: none;
}
.modal-card-head {
  justify-content: space-between;
}
.avatar {
  width: 28px;
  height: 28px;
  vertical-align: middle;
}
.rounded-button {
  width: 50px;
  height: 50px;
  margin: 0 10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #dddddd;
  cursor: pointer;
}

.rounded-button.active {
  background: #bbb;
}
.row {
  display: flex;
  justify-content: left;
  margin-bottom: 15px;
}

.file-previews.single img {
  width: 100%;
  max-width: 500px;
}

.file-previews.multi img {
  width: 200px;
  border-radius: 20px;
}
</style>
