<template>
  <div>
    <div v-if="item.length === 0">No templates available</div>
    <div v-else ref="tagContainer" class="tag-container" :class="{ 'is-expanded': expanded }">
      <div
        v-for="(tag, index) in displayedTagItems"
        :key="tag.index + 'tag' + index"
        @click="select(tag.index)"
        ref="tags"
        class="round-tag"
        :class="{ 'selected-tag': tag.isActive }"
        v-show="tag.visible"
      >
        <img :src="tag.image || tag.avatar" alt="" class="avatar img" style="display: inline-block" />
        <span class="lab">{{ tag.name }}</span>
      </div>
      <button
        v-if="!expanded && item.length > displayedTagItems.length"
        @click="expanded = true"
        class="button is-roundedis-small expand-button"
      >
        <b-icon icon="chevron-down" size="is-small"></b-icon>
      </button>
      <button v-if="!expanded" @click="expanded = true" class="button is-roundedis-small expand-button">
        <b-icon icon="chevron-down" size="is-small"></b-icon>
      </button>
    </div>
  </div>
</template>

<script>
import { debounce } from "lodash";

export default {
  props: {
    item: {
      type: Array,
      default: () => [],
    },
    selectedIndex: {
      type: Number,
      default: 0,
    },
    expandOnLoad: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expanded: this.expandOnLoad,
      tagsWidths: [],
      containerWidth: 0,
      nbItemsThatFits: 3, //default
    };
  },
  mounted() {
    this.debouncedCalculateWidths = debounce(this.calculateWidths, 100);
    this.calculateWidths();
    window.addEventListener("resize", this.debouncedCalculateWidths); // Handle window resize
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.debouncedCalculateWidths); // Cleanup
  },
  computed: {
    displayedTagItems() {
      var fitting = this.nbItemsThatFits;
      if (this.expanded) {
        fitting = this.tagItems.length;
      }
      // Compute visibility based on valid widths.
      return this.tagItems.map((tag, index) => {
        tag.visible = index < fitting;
        return tag;
      });
    },
    tagItems() {
      return this.item.map((tag, index) => {
        tag.isActive = this.selectedIndex === index;
        tag.index = index;
        return tag;
        return {
          name: tag.name,
          image: tag.image,
          bot: tag.bot,
          isActive: this.selectedIndex === index,
          index: index,
        };
      });
    },
  },
  methods: {
    calculateWidths() {
      this.$nextTick(() => {
        const MARGIN_RIGHT_CONTAINER = 200;
        if (!this.item.length) return;
        this.containerWidth = this.$refs.tagContainer?.clientWidth - MARGIN_RIGHT_CONTAINER;

        // Map through the tags and get their clientWidth
        this.tagsWidths = Array.from(this.$refs.tags).map((tag) => tag.clientWidth);

        this.$nextTick(() => {
          var allWidthsValid = true;

          // Check to ensure all tags have valid widths.
          this.tagsWidths.forEach((width) => {
            if (width === 0) {
              allWidthsValid = false;
              return;
            }
          });

          // If all widths are good, recompute number we can show
          if (allWidthsValid) {
            let totalWidth = 0;
            this.nbItemsThatFits = 0;

            for (let i = 0; i < this.tagsWidths.length; i++) {
              totalWidth += this.tagsWidths[i];
              if (totalWidth <= this.containerWidth) {
                this.nbItemsThatFits++;
              } else {
                break;
              }
            }
          }
        });
      });
    },
    select(index) {
      if (this.selectedIndex === index) {
        this.$emit("select", null);
      } else {
        this.$emit("select", index);
      }
    },
  },
  watch: {
    item: "calculateWidths", // Recalculate widths when tags change
  },
};
</script>

<style scoped>
.tag-container {
  display: flex;
  flex-wrap: nowrap; /* To ensure tags don't wrap */
  margin-bottom: 15px;
}

.tag-container.is-expanded {
  flex-wrap: wrap; /* Allow wrapping when expanded */
}

.round-tag {
  display: flex;
  align-items: center;
  padding: 3px;
  padding-right: 6px;
  border-radius: 200px;
  border: 1px solid #ddd;
  margin-right: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  white-space: nowrap;
  background: white;
  font-size: 12px;
  font-weight: 400;
}

.selected-tag {
  border: 1px solid blue;
}

.avatar {
  border-radius: 50%;
  margin-right: 10px;
}

.img {
  width: 33px;
  height: 33px;
  display: block;
  background: #ccc;
}
.lab {
  padding-right: 10px;
}

.expand-button {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  margin: 0;
  padding: 0;
  font-size: 15px;
  height: 33px;
  width: 33px;
}
</style>
