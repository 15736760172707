<template>
  <div class="new-post-teaser" v-if="bot">
    <div class="new-post-promo box">
      <div class="row">
        <router-link :to="bot.url">
          <img class="user-avatar avatar" :src="bot.avatar" />
        </router-link>
        <div class="start-post" @click="open('Text')">
          <p>Create a post</p>
        </div>
      </div>
      <div class="row button-row">
        <div v-for="button in buttons" :key="button.id" class="button icon-button" @click="open(button.id)">
          <span class="icon">
            <i :class="[button.icon, button.color]"></i>
          </span>
          <span>{{ button.id }}</span>
          <!-- 
        <i :class="[button.icon, button.color]"></i>
        <span>{{ button.id }}</span> -->
        </div>
      </div>
    </div>

    <!-- new post modal is here -->
    <b-modal
      v-model="isComponentModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Example Modal"
      close-button-aria-label="Close"
      aria-modal
    >
      <template #default="props">
        <new-post-modal
          :bot="bot"
          :bots="bots"
          :wantImage="wantImage"
          :type="selectedNewPostItem"
          @close="props.close"
        ></new-post-modal>
      </template>
    </b-modal>
  </div>
</template>

<script>
import NewPostModal from "@/components/studio/NewPostModal.vue";

export default {
  components: {
    NewPostModal,
  },
  props: {
    avatarLink: {
      type: String,
      // required: true,
    },
    avatar: {
      type: String,
      //  required: true,
    },
    /*
    type: {
      type: String,
      default: "Text",
    },*/
    bot: {
      type: Object,
      default: null,
    },
    /*
    bots: {
      type: Array,
      default: () => [],
    },*/
  },
  data() {
    return {
      isComponentModalActive: false,
      type: null,
      wantImage: false,
      buttons: [
        { id: "Image", icon: "fa fa-image", color: "color-red" },
        { id: "AI Image", icon: "fa fa-sparkles", color: "color-blue" },
        // { id: "Video", icon: "fa fa-video", color: "color-green" },
        { id: "Article", icon: "fa fa-newspaper", color: "color-orange" },
      ],
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      if (!this.bot) {
        window.API.me.getUserBotsOwner().then((bots) => {
          this.bots = bots;
          this.loadingBots = false;
          this.bot = bots[0];
        });
        /*
        window.API.botProfileOwner(id, {}).then((b) => {
          this.bot = b;
          this.loading = false;
          // console.log("botJ!", bot, bot.id);
        });*/
      }
    },
    open(id) {
      this.selectedNewPostItem = id;

      // console.log("Opening: ", id);
      //emit event with param to parrent
      this.$emit("open", id);

      if (id == "Article") {
        // this.$router.push({ name: "newArticle", params: { bot: this.bot.handle } });

        this.$router.push(this.bot.url + "/new-article");
        return true;
      } else if (id == "Video") {
        this.$router.push({ name: "BotVideo", params: { bot: this.bot.handle } });
      } else if (id == "AI Image") {
        var u = this.bot.url + "/images"; //"/posts/new?ai=true";
        this.$router.push(u);
      } else {
        //text or imgage
        this.wantImage = id == "Image";

        console.log("openNewPostModal", id);
        this.isComponentModalActive = true;
      }
    },
  },
};
</script>

<style scoped>
.new-post-promo {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
  margin-bottom: 20px;
  max-width: 600px;
  border-radius: 10px;
}
.row {
  display: flex;
  margin-bottom: 3px;
}
.user-avatar {
  height: 50px;
  width: 50px;
  margin-right: 15px;
}
.start-post {
  flex-grow: 1;
  border-radius: 90px;
  padding: 10px;
  background: #fafafa;
  border: 1px solid #ddd;
  /* align text vertically  */
  display: flex;
  padding-left: 30px;
  align-items: center;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  color: #999;
}
.start-post:hover {
  background: #eee;
}
.button-row {
  justify-content: space-between;
}
.button {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  background: none;
  font-weight: 600;
  font-size: 16px;
}

.icon-button {
  display: flex;
  align-items: center;
}
.icon-button i {
  margin-right: 5px;
}
.icon-button:hover {
  background: #eeeeee;
}

/* Color classes for the icons */
.color-red {
  color: rgb(0, 123, 255);
}
.color-blue {
  color: rgb(0, 255, 183);
}
.color-green {
  color: rgb(6, 122, 6);
}
.color-orange {
  color: rgb(224, 109, 15);
}

.icon {
  font-size: 20px;
}
</style>
