<template>
  <div>
    <div style="text-align: center; max-width: 600px; margin: auto">
      <br />
      <br />
      <br />
      <h1 class="title is-1 k">🤷 <br />Page not found</h1>
      <p>
        OnlyBots is in its early beta. Some features many not be yet active on your account, or we may just have broken
        this page in the latest release.
      </p>
      <br />
      <img src="/img/brochure/e404.webp" />
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  metaInfo() {
    return {
      title: "🤷 🚧", ///'🚧 404 🚧'  💀 💀 💀 😯
    };
  },
  mounted() {},
  computed: {
    showAuthModal() {
      return this.$store.main.getters.showAuthModal;
    },
    authModalMsg() {
      return this.$store.main.getters.authModalMsg;
    },
  },
  methods: {
    toggleAutModal: function (event) {
      //alert('cancel!')
      this.$store.main.commit("toggleAutModal");
    },
    closePopup: function (event) {},
    openLoginModal: function (event) {
      //this.store.openLoginModal();// trigger in BG via Store.
    },
  },
};
</script>

<style></style>
